import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './Components/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Profilepage from './Components/Profilepage/Profilepage';
import SignupPage from './Components/SignupLogin/SignupPage';
import LoginPage from './Components/SignupLogin/LoginPage';
import LeaderUpadte from './Components/Leader/LeaderUpdate';
import AddLeader from './Components/AddLeader/AddLeader';
import AdminLogin from './Components/AdminLogin/AdminLogin';
import ConnectWithUsResponses from './Components/ConnectWithUs/ConnectWithUsResponses';
import AdminDashboard from './Components/AdminDashboard/AdminDashboard';
import Faq from './Components/FAQ/Faq';
import AboutUs from './Components/Aboutus/AboutUs';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import LeaderLogin from './Components/Leader/LeaderLogin';
import OrganizationEditForm from './Components/AdminDashboard/OrganizationEditForm';
import OrganizationList from './Components/AdminDashboard/OrganizationList';
import AdminVerifyOrganizations from './Components/AdminDashboard/AdminVerifyOrganizations';
import IndividualList from './Components/AdminDashboard/IndividualList';
import { MetaProvider } from './MetaContext'; // Import the MetaProvider
import './App.css';
import BlogList from './Components/Blog/BlogList';
import LinkedInCallback from './Components/LinkedInCallback';
import EndorsementGrid from './Components/Profilepage/Endorsementiframe';
import EndorsementIframe from './Components/Profilepage/Endorsementiframe';
import LeadersList from './Components/Leader/LeadersList';
import EventManager from './Components/AdminDashboard/EventManager';
import EventRSVPList from './Components/AdminDashboard/EventRSVPList';
import RSVPPage from './Components/Profilepage/RSVPage';
import EmailSender from './EmailSender';
// import BlogDetail from './Components/Blog/BlogDetail';

function App() {
  const [location, setLocation] = useState([]);
  const currentLocation = useLocation();
  const handleLocationSelect = (loc) => {
    setLocation(loc);
  };
  const isEndorsementRoute = currentLocation.pathname.startsWith('/rsvp/');
  return (
    <MetaProvider>
        <div>
        {!isEndorsementRoute && (
            <Navbar handleLocationSelect={handleLocationSelect} />
          )}
          <Routes>
            <Route path="/" element={<Home location={location} />} />
            <Route path="/profile/:slug" element={<Profilepage />} />
            <Route path="/leader" element={<LeadersList />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/update/:id" element={<LeaderUpadte />} />
            <Route path="/addLeader" element={<AddLeader />} />
            <Route path="/Adminlogin" element={<AdminLogin />} />
            <Route path="/ShowResponses" element={<ConnectWithUsResponses />} />
            <Route path="/AdminDashboard" element={<AdminDashboard />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/Leaderlogin" element={<LeaderLogin />} />
            <Route path="/organizations/edit/:id" element={<OrganizationEditForm />} />
            <Route path="/verificationorg" element={<AdminVerifyOrganizations />} />
            <Route path="/organizationedit" element={<OrganizationList />} />
            <Route path="/indivisualedit" element={<IndividualList />} />
            <Route path="/blog" element={<BlogList />} />
            <Route path="/linkedin-callback" element={<LinkedInCallback />} />
            <Route path="/endorsement/:slug" element={<EndorsementIframe />} />
            <Route path="/eventform" element={<EventManager />} />
            <Route path="/event/:eventId/rsvps" element={<EventRSVPList />} />
            <Route path="/rsvp/:eventId" element={<RSVPPage />} />
            <Route path='/sendmail' element={<EmailSender/>}/>
        {/* <Route path="/blog/:id" element={<BlogDetail />} /> */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          {!isEndorsementRoute && <Footer />}
                  </div>

    </MetaProvider>
  );
}

export default App;
